import { useRuntimeConfig } from "nuxt/app";

export function strippedURL(link) {
  const config = useRuntimeConfig();
  const countryPrefix = config.public["storyblokCountryPrefix"];
  const countryPrefixRegexp = new RegExp(`^/?${countryPrefix}/`);
  const trailingSlashRegexp = new RegExp("/$");
  const homeRegexp = new RegExp("^/home$");
  const isString = typeof link === "string" || link instanceof String;

  let url = link;

  if (!isString) {
    url = link?.story?.url ? `${link?.story?.url}` : link?.cached_url ? `${link?.cached_url}` : `${link?.url}`;
    url = link?.anchor ? `${url}#${link?.anchor}` : url;
  }
  if (
    !url.includes("http") &&
    !url.includes("javascript") &&
    !url.includes("tel:") &&
    !url.includes("mailto:") &&
    url[0] !== "/"
  ) {
    url = "/" + url;
  }

  return url
    .replace(countryPrefixRegexp, "/")
    .replace(trailingSlashRegexp, "")
    .replace(homeRegexp, "/");
}
