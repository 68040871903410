<template>
  <NuxtLink
    v-if="to || link.linktype === 'story'"
    class="simple-button"
    :class="{ xl: xl, round: round, solid: solid, flat: flat }"
    :target="link?.target"
    :to="url"
    :title="title"
  >
    <slot />
  </NuxtLink>
  <a
    v-else-if="href || link.linktype === 'url'"
    class="simple-button"
    :class="{ xl: xl, round: round, solid: solid, flat: flat }"
    :href="url"
    :target="link.target || '_blank'"
    :title="title || targetblankTitle"
  >
    <slot />
  </a>
  <button
    v-else
    class="simple-button"
    :class="{ xl: xl, round: round, solid: solid, flat: flat }"
    :disabled="disabled"
    :title="title"
  >
    <slot />
  </button>
</template>

<script setup>
const config = useRuntimeConfig();

const props = defineProps({
  to: {
    type: [String, undefined],
    required: false,
    default: () => undefined,
  },
  href: {
    type: [String, undefined],
    required: false,
    default: () => undefined,
  },
  link: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  xl: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  round: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  solid: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  flat: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  title: {
    type: String,
    required: false,
    default: "",
  },
});

const url = computed(() => {
  return props.href || strippedURL(props.to || props.link || "");
});

const targetblankTitle = computed(() => {
  return (
    (!props.link?.target || props.link.target === "_blank") &&
    useMicroCopy("simplebutton.targetblank.title")
  );
});
</script>

<style lang="scss" scoped>
.simple-button {
  position: relative;
  font-family: "Manrope", sans-serif;
  cursor: pointer;

  &.link {
    font-weight: 700;
    line-height: 150%;
    text-decoration-line: underline;
  }
}

.simple-button:not(.link) {
  background-color: var(--parent-bg);
  background-clip: padding-box;
  border: 2px solid transparent;
  width: fit-content;
  box-sizing: border-box;
  border-radius: $radius-m;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.5rem;
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: 700;
  color: var(--text-100);
  text-decoration: none;
  @include for-desktop-up {
    font-size: 1.15625rem;
  }

  &:before {
    content: "";
    position: absolute;
    @include inset(0);
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
    background-color: var(--gradient-start);
    background: linear-gradient(
      136deg,
      var(--gradient-start) 0%,
      var(--gradient-end) 100%
    );
  }

  &:not(.solid):before {
    background-color: var(--gradient-alt-start);
    background: linear-gradient(
      136deg,
      var(--gradient-alt-start) 0%,
      var(--gradient-alt-end) 100%
    );
  }

  &.xl {
    $height: 3.5rem;
    min-height: $height;
    border-radius: calc($height / 2);
    padding: 0 2rem;
    font-size: 1.15625rem;

    &.round {
      padding: 0;
    }
  }

  &.round {
    $size: 3.5rem;
    height: $size;
    width: $size;
    padding: 0;
    border-radius: calc($size / 2);

    &.solid {
      --gradient-start: var(--text-100);
      --gradient-end: var(--text-100);

      &:before {
        background-color: var(--text-100);
        background: linear-gradient(
          136deg,
          var(--gradient-start) 0%,
          var(--gradient-end) 100%
        );
      }
    }
  }

  &.solid {
    background-color: transparent;
    color: var(--text-reverse-100);

    &.flat {
      &::before {
        background: transparent;
      }
    }
  }

  &.flat {
    color: var(--text-reverse);
    --gradient-start: var(--text-reverse);
    --gradient-end: var(--text-reverse);

    &:before {
      background-color: var(--text-reverse);
      background: linear-gradient(
        136deg,
        var(--gradient-start) 0%,
        var(--gradient-end) 100%
      );
    }
  }

  &:deep(svg) {
    width: 1rem;
    height: auto;

    &:not(:last-child) {
      width: 1.5rem;
      height: auto;
      margin-right: 0.5rem;
    }
  }

  &:disabled {
    cursor: default;
    color: var(--button-disabled-text);

    &:before {
      background: var(--button-disabled-bg);
    }
  }
}
</style>
