import { defineStore } from "pinia";
import { useRuntimeConfig, useFetch } from "nuxt/app";

export const useDatasourcesStore = defineStore("datasources", {
  state: () => {
    return {
      entries: [],
      tagEntries: [],
    };
  },
  getters: {
    getEntries: (state): any => {
      return state.entries;
    },
    getTagEntries: (state): any => {
      return state.tagEntries;
    },
  },
  actions: {
    async fetchEntries() {
      async function datasourcesEntries() {
        const config = useRuntimeConfig();
        const randomVal = Math.random().toString();
        const { data } = await useFetch(
          `https://api.storyblok.com/v2/cdn/datasource_entries?token=${config.public["storyblokWebToken"]}&datasource=${config.public["country"]}&cv=${randomVal}&per_page=1000`
        );

        return data.value;
      }

      this.entries = await datasourcesEntries();
    },
    async fetchTagEntries() {
      async function datasourcesTagEntries() {
        const config = useRuntimeConfig();
        const { data } = await useFetch(
          `https://api.storyblok.com/v2/cdn/datasource_entries?token=${config.public["storyblokWebToken"]}&datasource=all-countries-tags&dimension=${config.public["country"]}&cv=${Date.now()}&per_page=1000`
        );

        return data.value;
      }

      this.tagEntries = await datasourcesTagEntries();
    },
  },
});
