import { defineRule } from 'vee-validate';
import { isValidPhoneNumber, isSupportedCountry } from 'libphonenumber-js';

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig();

  defineRule('required', value => {
    if (!value) {
      return useMicroCopy('form.required.error.label');
    };
  
    return true;
  });

  defineRule('tel', value => {
    const countryCode = config.public["country"].toUpperCase();

    if(value) {
      if (value.startsWith('+') && !isValidPhoneNumber(value)) {
        return useMicroCopy('form.invalid.international.phone.error.label');
      };
      if (!value.startsWith('+') && isSupportedCountry(countryCode) && !isValidPhoneNumber(value, countryCode)) {
        return useMicroCopy('form.invalid.national.phone.error.label');
      };
    }

    return true;
  });

  defineRule('email', value => {
    if(value) {
      const isValid = value.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  
      if (!isValid) {
        return useMicroCopy('form.invalid.mail.error.label');
      };
    }

    return true;
  });

  defineRule('alpha', value => {
    const isValid = value.match(
      /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*$/
    );

    if (!isValid) {
      return useMicroCopy('form.invalid.alpha.only.label');
    };

    return true;
  });
});
