
import * as storyblokRuntime$QIPYk9ciyo from '/usr/src/app/node_modules/@nuxt/image-edge/dist/runtime/providers/storyblok'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "phone": 320,
    "tablet-portrait": 600,
    "tablet-landscape": 900,
    "desktop": 1200,
    "big-desktop": 1800
  },
  "presets": {},
  "provider": "storyblok",
  "domains": [],
  "alias": {}
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$QIPYk9ciyo, defaults: {"baseURL":"https://a.storyblok.com"} }
}
        