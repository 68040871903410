import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/usr/src/app/.nuxt/floating-vue.mjs";
import plugin_yqYgmG0R8w from "/usr/src/app/node_modules/nuxt-lazy-hydrate/dist/runtime/plugin.mjs";
import plugin_eTVJQYlCmx from "/usr/src/app/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugin_OrkQhMqHci from "/usr/src/app/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import plugin_KlVwwuJRCL from "/usr/src/app/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import apolloConfig_FB2O5ESYzw from "/usr/src/app/plugins/apolloConfig.ts";
import fetchStoreEntries_esq3BoaKa0 from "/usr/src/app/plugins/fetchStoreEntries.js";
import i18n_iso_countries_njK6AOmV7j from "/usr/src/app/plugins/i18n-iso-countries.js";
import vee_validate_K3WwmJMPDb from "/usr/src/app/plugins/vee-validate.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  floating_vue_EIcJ7xiw0h,
  plugin_yqYgmG0R8w,
  plugin_eTVJQYlCmx,
  plugin_OrkQhMqHci,
  plugin_KlVwwuJRCL,
  chunk_reload_client_UciE0i6zes,
  apolloConfig_FB2O5ESYzw,
  fetchStoreEntries_esq3BoaKa0,
  i18n_iso_countries_njK6AOmV7j,
  vee_validate_K3WwmJMPDb
]