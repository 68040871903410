<template>
  <NuxtLayout name="default" :is-error="true">
    <div class="background background-error-page">
      <div class="error-page section">
        <div class="content section-inner">
          <StoryblokComponent v-if="image" :blok="image" class="image" />
          <h1 v-else class="bold">{{ error.statusCode }}</h1>
          <div class="size-s" v-html="description" />
          <SimpleButton xl @click="handleError">{{ ctaLabel }}</SimpleButton>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup>
const props = defineProps({
  error: {
    type: Object,
    required: true,
  },
});

console.error(props.error);
console.error(props.error.message, "\n", props.error.stack);

const config = useRuntimeConfig();
const countryPrefix = config.public["storyblokCountryPrefix"];

let story;

try {
  story = await useAsyncStoryblok(countryPrefix + "/errors", {
    version: config.public["storyblokVersion"],
  });
} catch (error) {
  console.error(error);
}

const sbError = computed(() => {
  return story?.value.content.errors.find((e) => {
    return parseInt(e.code) === props.error.statusCode;
  });
});

const image = computed(() => {
  return sbError.value?.image?.[0];
});

const description = computed(() => {
  return (
    useRenderRichText(sbError.value?.description) ||
    useRenderRichText(story?.value.content.description)
  );
});

const ctaLabel = computed(() => {
  return sbError.value?.cta_label || story?.value.content.cta_label;
});

const redirectUrl = computed(() => {
  return (
    (sbError.value?.cta_link &&
      strippedURL(sbError.value.cta_link)) ||
    (story?.value.content.cta_link &&
      strippedURL(story.value.content.cta_link))
  );
});

const handleError = () => clearError({ redirect: redirectUrl.value });
</script>

<style lang="scss" scoped>
.background-error-page {
  background: var(--solid-02);
  --parent-bg: var(--solid-02);
}
.error-page {
  padding: var(--header-height) 0 0;
  margin-bottom: 1.125rem;

  .section-inner {
    @include for-tablet-portrait-up {
      grid-column: 6/-6;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1.5rem;
    margin: 7.5rem 0;

    @include for-tablet-portrait-up {
      margin: 6.25rem 0 7.5rem;
      gap: 2.5rem;
    }
  }

  .image {
    &,
    :deep(img) {
      width: 100%;
    }
  }

  h1 {
    font-size: 50vw;
    line-height: 85%;
    color: var(--solid-03);

    @include for-tablet-portrait-up {
      font-size: 28vw;
    }
  }
}
</style>
