import { useDatasourcesStore } from "../stores/datasources";
import { useStringParams } from "./StringParams";

export function useMicroCopy(key, params) {
  const store = useDatasourcesStore();
  const item = store.getEntries?.datasource_entries?.filter((entry) => {
    return entry.name === key;
  });

  let result = item && item.length ? item[0]?.value : null;

  return result === "IS_EMPTY" ? "" : useStringParams(result, params);
}

export function useMicroCopyTagLabel(key) {
  const store = useDatasourcesStore();
  const item = store.getTagEntries?.datasource_entries?.filter((entry) => {
    return entry.value === key;
  });
  return item && item.length ? item[0]?.dimension_value : "";
}
