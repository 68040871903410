import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAnimatedOnboardingFlow, LazyAnimatedServices, LazyAssetAudio, LazyAssetImage, LazyAssetVideo, LazyAssetWithBackground, LazyBlokDownload, LazyBlokHeading, LazyBlokIframe, LazyBlokRte, LazyBlokValues, LazyCollectionAccordion, LazyCollectionEditorial, LazyCollectionList, LazyCollectionNews, LazyCollectionPanel, LazyCollectionResourceArticles, LazyCollectionResourceGuides, LazyCollectionTab, LazyCollectionTabs, LazyContactInformations, LazyDomusviTeam, LazyEditorialAttribute, LazyEditorialCard, LazyEditorialMosaic, LazyEditorialSection, LazyEventContent, LazyEventHero, LazyFacilitiesNearby, LazyFacilityAttribute, LazyFacilityAttributeCategory, LazyFacilityAttributes, LazyFacilityBar, LazyFacilityIsoLogos, LazyFacilityMap, LazyFacilityOverview, LazyFacilityOverviewMap, LazyFacilityReview, LazyFacilityReviews, LazyFacilityTwoAttributes, LazyFaqList, LazyFocusBlock, LazyFormBuilderGroup, LazyFormBuilderInput, LazyFormBuilderSection, LazyGenericContent, LazyHeroBanner, LazyHomeContent, LazyIllustratedRating, LazyKeyFigure, LazyKeyFigures, LazyLandingCountrySeo, LazyLandingRegionSeo, LazyLandingSearchBar, LazyListWithTitle, LazyMediaPlayer, LazyMediaTextCta, LazyMoreHelp, LazyNews, LazyNewsContent, LazyNewsList, LazyPageFacilityNewsHub, LazyPageFaq, LazyPageGeneric, LazyPageHome, LazyPageHomecare, LazyPageLandingCountry, LazyPageLandingEvent, LazyPageLandingRegion, LazyPageLocator, LazyPageNewsGlobal, LazyPageNewsGlobalHub, LazyPageNursingHome, LazyPageResidency, LazyPageResourceArticle, LazyPageResourceCategory, LazyPageResourceHub, LazyPageResourceResults, LazyPageService, LazyPageServiceHub, LazyPdfDownload, LazyRelatedFacilities, LazyResourceCategories, LazyResourceListByTag, LazySearchHero, LazySectionContained, LazySectionFullwidth, LazyServiceHubContent, LazyServicePage, LazySingleAccordion, LazyStaffCard, LazyStaffMosaic, LazyStaffTestimonial, LazyTextMedia, LazyVirtualVisit, LazyYoutubePlayer } from '#components'
const lazyGlobalComponents = [
  ["AnimatedOnboardingFlow", LazyAnimatedOnboardingFlow],
["AnimatedServices", LazyAnimatedServices],
["AssetAudio", LazyAssetAudio],
["AssetImage", LazyAssetImage],
["AssetVideo", LazyAssetVideo],
["AssetWithBackground", LazyAssetWithBackground],
["BlokDownload", LazyBlokDownload],
["BlokHeading", LazyBlokHeading],
["BlokIframe", LazyBlokIframe],
["BlokRte", LazyBlokRte],
["BlokValues", LazyBlokValues],
["CollectionAccordion", LazyCollectionAccordion],
["CollectionEditorial", LazyCollectionEditorial],
["CollectionList", LazyCollectionList],
["CollectionNews", LazyCollectionNews],
["CollectionPanel", LazyCollectionPanel],
["CollectionResourceArticles", LazyCollectionResourceArticles],
["CollectionResourceGuides", LazyCollectionResourceGuides],
["CollectionTab", LazyCollectionTab],
["CollectionTabs", LazyCollectionTabs],
["ContactInformations", LazyContactInformations],
["DomusviTeam", LazyDomusviTeam],
["EditorialAttribute", LazyEditorialAttribute],
["EditorialCard", LazyEditorialCard],
["EditorialMosaic", LazyEditorialMosaic],
["EditorialSection", LazyEditorialSection],
["EventContent", LazyEventContent],
["EventHero", LazyEventHero],
["FacilitiesNearby", LazyFacilitiesNearby],
["FacilityAttribute", LazyFacilityAttribute],
["FacilityAttributeCategory", LazyFacilityAttributeCategory],
["FacilityAttributes", LazyFacilityAttributes],
["FacilityBar", LazyFacilityBar],
["FacilityIsoLogos", LazyFacilityIsoLogos],
["FacilityMap", LazyFacilityMap],
["FacilityOverview", LazyFacilityOverview],
["FacilityOverviewMap", LazyFacilityOverviewMap],
["FacilityReview", LazyFacilityReview],
["FacilityReviews", LazyFacilityReviews],
["FacilityTwoAttributes", LazyFacilityTwoAttributes],
["FaqList", LazyFaqList],
["FocusBlock", LazyFocusBlock],
["FormBuilderGroup", LazyFormBuilderGroup],
["FormBuilderInput", LazyFormBuilderInput],
["FormBuilderSection", LazyFormBuilderSection],
["GenericContent", LazyGenericContent],
["HeroBanner", LazyHeroBanner],
["HomeContent", LazyHomeContent],
["IllustratedRating", LazyIllustratedRating],
["KeyFigure", LazyKeyFigure],
["KeyFigures", LazyKeyFigures],
["LandingCountrySeo", LazyLandingCountrySeo],
["LandingRegionSeo", LazyLandingRegionSeo],
["LandingSearchBar", LazyLandingSearchBar],
["ListWithTitle", LazyListWithTitle],
["MediaPlayer", LazyMediaPlayer],
["MediaTextCta", LazyMediaTextCta],
["MoreHelp", LazyMoreHelp],
["News", LazyNews],
["NewsContent", LazyNewsContent],
["NewsList", LazyNewsList],
["PageFacilityNewsHub", LazyPageFacilityNewsHub],
["PageFaq", LazyPageFaq],
["PageGeneric", LazyPageGeneric],
["PageHome", LazyPageHome],
["PageHomecare", LazyPageHomecare],
["PageLandingCountry", LazyPageLandingCountry],
["PageLandingEvent", LazyPageLandingEvent],
["PageLandingRegion", LazyPageLandingRegion],
["PageLocator", LazyPageLocator],
["PageNewsGlobal", LazyPageNewsGlobal],
["PageNewsGlobalHub", LazyPageNewsGlobalHub],
["PageNursingHome", LazyPageNursingHome],
["PageResidency", LazyPageResidency],
["PageResourceArticle", LazyPageResourceArticle],
["PageResourceCategory", LazyPageResourceCategory],
["PageResourceHub", LazyPageResourceHub],
["PageResourceResults", LazyPageResourceResults],
["PageService", LazyPageService],
["PageServiceHub", LazyPageServiceHub],
["PdfDownload", LazyPdfDownload],
["RelatedFacilities", LazyRelatedFacilities],
["ResourceCategories", LazyResourceCategories],
["ResourceListByTag", LazyResourceListByTag],
["SearchHero", LazySearchHero],
["SectionContained", LazySectionContained],
["SectionFullwidth", LazySectionFullwidth],
["ServiceHubContent", LazyServiceHubContent],
["ServicePage", LazyServicePage],
["SingleAccordion", LazySingleAccordion],
["StaffCard", LazyStaffCard],
["StaffMosaic", LazyStaffMosaic],
["StaffTestimonial", LazyStaffTestimonial],
["TextMedia", LazyTextMedia],
["VirtualVisit", LazyVirtualVisit],
["YoutubePlayer", LazyYoutubePlayer],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
