import { default as _91_46_46_46slug_93pvGVZEAWcBMeta } from "/usr/src/app/pages/[...slug].vue?macro=true";
import { default as _91service_93MESFvLo1EjMeta } from "/usr/src/app/pages/nursing-home/editorial/[service].vue?macro=true";
import { default as iconsANLBcOX2AuMeta } from "/usr/src/app/pages/utils/icons.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93pvGVZEAWcBMeta || {},
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "nursing-home-editorial-service",
    path: "/nursing-home/editorial/:service()",
    component: () => import("/usr/src/app/pages/nursing-home/editorial/[service].vue").then(m => m.default || m)
  },
  {
    name: "utils-icons",
    path: "/utils/icons",
    meta: iconsANLBcOX2AuMeta || {},
    component: () => import("/usr/src/app/pages/utils/icons.vue").then(m => m.default || m)
  }
]